import { ConfirmationPage } from "@components/web/ConfirmationPage";
import Box from "@material-ui/core/Box";

export default () => {
  return <Box
    style={{
      height: "100vh",
      position: "relative"
    }}>
    <Box
      style={{
        position: "absolute",
        top: "50%", left: "50%",
        transform: "translate(-50%, -50%)"
      }}>
      <ConfirmationPage showButton={false} />
    </Box>
  </Box>

}
